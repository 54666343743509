// Fonts
@font-face {
    font-family: 'Proxima Nova';
    src: url(../scss/fonts/ProximaNovaFont.otf) format('opentype');
}
// Variables
@import "variables";


@import "partials/navbar";
@import "partials/landing";
@import "partials/footer";
@import "partials/about";
@import "partials/privacy";

// Bootstrap
@import "bootstrap/scss/bootstrap";
