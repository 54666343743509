.navbar{
    padding-top: 20px !important;
    .nav-item{
        margin-left: 20px;
        margin-right: 20px;
        .nav-link{
            font-weight: 700;
            color: rgba(0, 0, 0, 1) !important;
            font-size: 16px;
        }
    }
}