*{
    font-family: Proxima Nova;
}
.content{
    padding-top: 80px !important;
    min-height: 75vh;
    .underline{
        // padding: 2px;
        height: 0px;
        max-width:104px;
        background-color: $primary;
        display: block;
        border: 2px solid $primary;
        border-radius: 35px;
        transform: rotate(0.09deg);
    }
    .email-div{
        display: flex;
        border-color: $primary;
        box-shadow: 0 0 0 0.2rem $primary;
        max-width: 400px;
        height: 40px;
        &:focus-within{
            color: #495057;
            background-color: #fff;
            outline: 0;
        }
        
        
        input{
            // width: 100%;
            height: 100%    ;
            outline: none;
            padding: 0;
            margin: 0;
            box-shadow: none;
            border: none;
            background-image: none !important;
            &:focus{
                outline: none;
                box-shadow: none;
                border: none;
                background-image: none !important;
            }
        }
        button{
            border-radius: 0 !important;
        }

    }
}

.main-text{
    color: #22215B;
    // font-family: Gilroy;
    // font-size: 45px;
    font-weight: 600;
    // letter-spacing: -.8999999761581421px;
    // line-height: 59px;
    font-family: Gilroy;
    font-size:calc(35px + 2.5vw);
    line-height: calc(50px + 2.5vw);
    /* or 94% */

    display: flex;
    align-items: center;
    letter-spacing: 1px;
}

.top-background{
    background-image: url("../../images/about-bg.svg");
    background-color: rgba(37,138,255,.09);
    background-position: right;
    background-repeat: no-repeat;
    border-radius: 27px;
    min-height: 80vh;
    align-items: center;
    .col{
        height: max-content;
    }
}

.cursor-pointer{
    cursor: pointer;
}

.card{
    .card-header{
        background: #F8FAFC;
        box-shadow: 0px 1px 4px rgba(23, 23, 37, 0.1);
        border-radius: 8px;
        border-bottom: none;
    }
    .inner-card{
        width: 50%;
        cursor: pointer;
        p{
            font-family: 'Gilroy';
            font-style: normal;
            font-weight: 500;
            font-size: 15px;
            letter-spacing: -0.9px;
            color: #22215B;
        }
        &:nth-child(1){
            border-right: .5px solid #d5dade;
        } 
        &:nth-child(2){
            border-left: .5px solid #d5dade;
        }
        hr{
            display: none;
        }
        &.active{
            background: #ececec !important;
            img, p{
                // opacity: 0.6;
            }
            hr{
                display: block;
                width: 50%;
                background-color: #258AFF;
                height: 2px;
                margin-top: 0;
                margin-bottom: 0;

            }
        }
    }
}


.color-text{
    font-family: Gilroy;
    font-weight: 600;
    font-size:calc(10px + 1.5vw);
    line-height: calc(25px + 1.5vw);
    .main-color{
        font-weight: bolder;
    }
}